<template>
  <nav class="funnel-navbar row">
    <div class="col-12">
      <div class="row ">
        <div class="col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start ma-0 pa-0">
          <slot
            name="logo"
          >
            <NuxtLink
              :to="localePath('index')"
              aria-label="logo homeloop"
              class="link"
            >
              <SvgLogoBlue class="funnel-navbar__logo" />
            </NuxtLink>
          </slot>
        </div>
        <div
          class="address col-12 col-md-6 d-flex align-items-center justify-content-center"
        >
          <div
            v-if="props.noOffer && address"
          >
            <SvgPin class="pin" />
            {{ `${address.street} ${address.postal_code} ${address.city}` }}
          </div>
        </div>

        <div class="col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-end">
          <HButton
            tag="link"
            target="_blank"
            color="night-blue"
            inverted
            :to="props.urlContact? props.urlContact : '/contact'"
            :block="smallerThanMd"
            class="mt-1 mt-md-0"
            :style="{height: '40px',
                     padding: !greaterThanLg ? '0': '10px 20px' }"
          >
            Nous contacter
          </HButton>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
import { useFunnel } from '~/composables/funnel'

const props = defineProps<{
  noOffer?: boolean
  urlContact: string
}>()

const localePath = useLocalePath()
const viewport = useViewport()
const smallerThanMd = computed(() => viewport.isLessThan('md'))
const greaterThanLg = computed(() => viewport.isGreaterThan('md'))

const { getCurrent } = useFunnel()
const address = getCurrent('addresses')

</script>

<style lang="scss" scoped>

.funnel-navbar {
  background-color: #FFF;
  backdrop-filter: blur(20px);
  padding: 1.875% 3.75%;
  position: sticky;
  border-bottom: 1px solid #E6E6EA;
  top: 0;
  z-index: 2;

  .row {
    justify-content: center;
  }

  .address {
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.85px;
    text-align: center;
    color: #0A0E34;
  }

  .link {
    height: 40px;
  }

  &__logo {
    width: 180px;
  }

  .pin {
    margin-right: 4px;
    height: 16px;
    width: 16px;

  }
}
</style>
